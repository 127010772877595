export default [
  {
    icon: 'HomeIcon',
    title: 'Inicio',
    route: 'cda-dashboard',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Administración',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Roles',
        route: 'roles-list',
        action: 'read',
        resource: 'user-roles-list',
      },
      {
        title: 'Gestión de Periodos',
        route: 'period-management',
        action: 'read',
        resource: 'period-management',
      },
      {
        title: 'Auxiliares',
        children:[
          {
            title: 'Gestión de Auxiliares',
            route: 'auxiliary-list',
            action: 'read',
            resource: 'auxiliary-management',
          },
          {
            title: 'Aprobación de Auxiliares',
            route: 'auxiliary-approval',
            action: 'read',
            resource: 'auxiliary-approval',
          },
        ]
      },
      {
        title: 'Consulta de Usuarios',
        route: 'users-list',
        action: 'read',
        resource: 'users-list',
      },
      {
        title: 'Homologación InterBancos',
        route: 'inter-bank-homologation',
        action: 'read',
        resource: 'inter-bank-homologation',
      },
    ],
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Gerencias Directivas',
        route: 'administrative-management',
        action: 'read',
        resource: 'administrative-management',
      },
      {
        title: 'Centros de Costos',
        route: 'cost-centers',
        action: 'read',
        resource: 'cost-center',
      },
      {
        title: 'Tipos de Centros de Costos',
        route: 'center-types',
        action: 'read',
        resource: 'cost-center-type',
      },
      {
        title: 'Cuentas Contables',
        route: 'accounts-accounting',
        action: 'read',
        resource: 'accounting-account',
      },
      {
        title: 'Tipos de Cuentas Contables',
        route: 'accounts-types',
        action: 'read',
        resource: 'cost-center-type',
      },
      {
        title: 'Categorías de Cuentas Contables',
        route: 'accounts-classification',
        action: 'read',
        resource: 'accounting-account-category',
      },
      {
        title: 'Rangos de Hipotecas',
        route: 'mortgage-range',
        action: 'read',
        resource: 'mortgage-loan-range',
      },
      {
        title: 'Reglas y Trámites',
        route: 'reports-rentability',
        action: 'read',
        resource: 'reports-rentability',
      },
    ],
  },
  {
    title: 'Reportes',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Reportes de Movimiento',
        route: 'reports-movements',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-profitability-movements-report',
      },
      {
        title: 'Catálogo de Auxiliares',
        route: 'reports-aux',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-aux-report',
      },
      {
        title: 'Rentabilidad Financiera',
        route: 'reports-financial-rentability',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-financial-rentability-report',
      },
      {
        title: 'Rentabilidad Distribuida',
        route: 'reports-redistribution',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-redistribution-report',
      },
      {
        title: 'Rentabilidad Financiera Dínamica',
        route: 'reports-financial-rentability-dynamic',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-financial-rentability-report-dynamic',
      },
      {
        title: 'Rentabilidad Distribuida Dínamica',
        route: 'reports-redistribution-dynamic',
        icon: 'ListIcon',
        action: 'read',
        resource: 'branch-redistribution-report-dynamic',
      },
    ],
  },
]
