<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.teinsa-pa.com/"
        target="_blank"
      >ACME Bank & Trust</b-link>
      <span class="d-none d-sm-inline-block">, Todos los Derechos Reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      Desarrollado por <b-link
        href="http://teinsa-pa.com"
        target="_blank"
      > Teinsa - {{ version }}</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import environment from '@/environment'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      version: environment.version,
    }
  },
}
</script>
